import axios from 'axios';
import { apiClient } from '../utils/api';

export async function sendOTPCode(phoneNumber) {
    return await apiClient.POST('api/twilio/verification/token', {
        phone: phoneNumber,
    });
}

export async function checkOTPCode(phoneNumber, otpCode) {
    return await apiClient.POST('api/appointment-token', {
        phone: phoneNumber,
        code: otpCode,
    });
}

export async function loginPortal(phoneNumber, otpCode, project) {
    return await axios.request({
        headers: {
            ...apiClient.config().headers,
        },
        method: 'POST',
        url: `${apiClient.apiUrl(
            `api/widget/project/${project}/login/phone/${phoneNumber}`
        )}`,
        data: {
            phone: phoneNumber,
            code: otpCode,
            project,
        },
    });
}

export async function updateLeadPortal(lead_id, data) {
    return await apiClient.POST(`api/widget/leads/${lead_id}`, data);
}

export async function getCSRFToken() {
    return await apiClient.GET('sanctum/csrf-cookie');
}
