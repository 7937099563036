import { SCREEN } from '../../utils/constants';
import { loadLocationList } from './locations';
import { loadCountriesCodeDropdown } from '../components/countries';
import {
  appUrl,
  selectedCodeNumber,
  selectedCountry,
} from '../../utils/variables';

export function loadInstantQuotes() {
  loadLocationList();
}

export function loadPhoneComponent() {
  document
      .querySelector('.phone__container .phone__selected-code img')
      .setAttribute(
          'src',
          `${appUrl}/assets/images/svg/${selectedCountry.toLowerCase()}.svg`
      );

  document.querySelector(
      '.phone__container .selected-country-code'
  ).innerHTML = '+' + selectedCodeNumber;

  document.querySelector(
      '.phone__container .phone__countryCode--select'
  ).innerHTML = loadCountriesCodeDropdown(SCREEN.PHONE);
}
