import { EVENT_MESSAGE } from '../../utils/constants';
import { phoneToCall } from '../../utils/variables';
import { toggleWidgetAssistant } from './assistantListeners';
import { openBookAppointment } from './movePages';

export function programButtonListener() {
    const assistantIframe = document.querySelector(
        '.assistant__container .assistant__content--iframe'
    );

    /* Select instant quote */
    if (
        document.querySelector(
            `*[data-id="assistant"][data-click="instant-quote-now"]`
        )
    ) {
        document
            .querySelectorAll(
                `*[data-id="assistant"][data-click="instant-quote-now"]`
            )
            .forEach(function (element) {
                element.onclick = (e) => {
                    checkAssistantWidget();
                    assistantIframe.contentWindow.postMessage(
                        'navigate' + EVENT_MESSAGE.INSTANT_QUOTE,
                        '*'
                    );
                    e.preventDefault();
                };
            });
    }

    /* Select Request consult */
    if (
        document.querySelector(
            `*[data-id="assistant"][data-click="schedule-consultation"]`
        )
    ) {
        document
            .querySelectorAll(
                `*[data-id="assistant"][data-click="schedule-consultation"]`
            )
            .forEach(function (element) {
                element.onclick = (e) => {
                    checkAssistantWidget();
                    assistantIframe.contentWindow.postMessage(
                        'navigate' + EVENT_MESSAGE.REQUEST_CONSULT,
                        '*'
                    );
                    e.preventDefault();
                };
            });
    }

    /* Select FAQs */
    if (document.querySelector(`[data-id="assistant"][data-click="faqs"]`)) {
        document.querySelector(
            `*[data-id="assistant"][data-click="faqs"]`
        ).onclick = (e) => {
            checkAssistantWidget();
            assistantIframe.contentWindow.postMessage(
                'navigate' + EVENT_MESSAGE.ASK_QUESTION,
                '*'
            );
            e.preventDefault();
        };
    }

    /* Select Text Us */
    if (document.querySelector(`[data-id="assistant"][data-click="text-us"]`)) {
        document.querySelector(
            `*[data-id="assistant"][data-click="text-us"]`
        ).onclick = (e) => {
            checkAssistantWidget();
            assistantIframe.contentWindow.postMessage(
                'navigate' + EVENT_MESSAGE.TEXT_US,
                '*'
            );
            e.preventDefault();
        };
    }

    /* Select Procedure Eligibility */
    if (
        document.querySelector(
            `*[data-id="assistant"][data-click="procedure-eligibility"]`
        )
    ) {
        document.querySelector(
            `*[data-id="assistant"][data-click="procedure-eligibility"]`
        ).onclick = () => {
            checkAssistantWidget();
            assistantIframe.contentWindow.postMessage(
                'navigate' + EVENT_MESSAGE.PROCEDURE_ELIGIBILITY,
                '*'
            );
        };
    }

    /* Select see all services */
    if (
        document.querySelector(
            `*[data-id="assistant"][data-click="see-all-services"]`
        )
    ) {
        document.querySelector(
            `*[data-id="assistant"][data-click="see-all-services"]`
        ).onclick = () => {
            checkAssistantWidget();
            assistantIframe.contentWindow.postMessage(
                'navigate' + EVENT_MESSAGE.SEE_ALL_SERVICES,
                '*'
            );
        };
    }

    /* Select Non-surgical services */
    if (
        document.querySelector(
            `*[data-id="assistant"][data-click="book-non-surgical-appointment"]`
        )
    ) {
        document.querySelector(
            `*[data-id="assistant"][data-click="book-non-surgical-appointment"]`
        ).onclick = () => {
            checkAssistantWidget();
            assistantIframe.contentWindow.postMessage(
                'navigate' + EVENT_MESSAGE.NON_SURGICAL_SERVICES,
                '*'
            );
        };
    }

    /* Select Call */
    if (document.querySelector(`[data-id="assistant"][data-click="call"]`)) {
        document.querySelector(
            `*[data-id="assistant"][data-click="call"]`
        ).onclick = () => {
            window.location.href = `tel:${phoneToCall}`;
        };
    }

    /* Open widget */
    if (document.querySelector(`*[data-id="assistant"][data-click="open"]`)) {
        document.querySelector(
            `*[data-id="assistant"][data-click="open"]`
        ).onclick = () => {
            let widgetToggleBtnDom = document.querySelector(
                '.assistant__container .assistant__button'
            );
            if (widgetToggleBtnDom) {
                widgetToggleBtnDom.click();
                openOrCloseWidget('remove');
            }
        };
    }

    /* Select Book Appointment */
    if (
        document.querySelector(
            `*[data-id="assistant"][data-click="book-appointment"]`
        )
    ) {
        document.querySelector(
            `*[data-id="assistant"][data-click="book-appointment"]`
        ).onclick = (e) => {
            checkAssistantWidget();
            const assistantIframeTemp = document.querySelector(
                '.assistant__container .assistant__content--iframe'
            );
            if (assistantIframeTemp) {
                assistantIframeTemp.contentWindow.postMessage(
                    'navigate' + EVENT_MESSAGE.BOOK_APPOINTMENT,
                    '*'
                );
            }
            e.preventDefault();
        };
    }
    /* Select Book Appointment */
    if (document.querySelector(`*[data-id="assistant"][data-click="popup"]`)) {
        document.querySelector(
            `*[data-id="assistant"][data-click="popup"]`
        ).onclick = (e) => {
            const selector = document.querySelector(
                'script[data-id="assistant"]'
            );
            const book_appointment = selector?.getAttribute('book_appointment');
            if (book_appointment) {
                const assistantIframeTemp = document.querySelector(
                    '.assistant__container .assistant__content--iframe'
                );
                if (assistantIframeTemp) {
                    assistantIframeTemp.contentWindow.postMessage(
                        'navigate' + EVENT_MESSAGE.BOOK_APPOINTMENT,
                        '*'
                    );
                }
            }
            checkAssistantWidget();
            e.preventDefault();
        };
    }
}

function checkAssistantWidget() {
    if (
        !document
            .querySelector('.assistant__container')
            .classList.contains('show')
    ) {
        toggleWidgetAssistant();
    }
}

/* close or open widget*/
export function openOrCloseWidget(type) {
    document
        .querySelector('.assistant__container .tooltip__text')
        ?.classList?.[type]('hidden');
    document
        .querySelector('.assistant__container .assistant__button')
        ?.classList?.[type]('hidden');
}
