import { initWidgetStyles } from './init';
import { tooltip } from './tooltip';
import { isModalPopup, primaryColor } from '../utils/variables';
import { mediaQuery } from './mediaQuery';
import { loadingStyles } from './loading';
import { footerStyles } from './footer';
import { initModalWidgetStyles } from './modalWidget';

export function createStyles() {
    let styles = ``;

    styles = styles
        .concat(initWidgetStyles(primaryColor))
        .concat(tooltip)
        .concat(loadingStyles)
        .concat(footerStyles)
        .concat(mediaQuery)
        .concat(isModalPopup ? initModalWidgetStyles() : []);

    const styleTag = document.createElement('style');
    styleTag.innerHTML = styles.replace(/^\s+|\n/gm, '');
    document.head.appendChild(styleTag);
}
