import { initialize } from '../views/init';
import { createStyles } from '../styles/styles';
import {
    assistantListeners,
    closeAssistant,
} from './events/assistantListeners';
import {
    openOrCloseWidget,
    programButtonListener,
} from './events/programButtonListeners';
import { params } from './events/movePages';
import {
    BOOK_APPOINTMENT_PARAM,
    ESTIMATE_ID_PARAM,
    EVENT_ID_PARAM,
    HIDE_WIDGET,
    IGNORE_IFRAME,
    IS_LOAD_INSTANCE_QUOTE,
    NEW_LEAD_UPLOAD,
    OPEN_BOOK_APPOINTMENT_MODAL,
    UPLOAD_ID_PARAM,
} from '../utils/constants';
import { isModalPopup } from '../utils/variables';

export function loadWidget() {
    window.addEventListener('keydown', (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
        }
    });

    window.addEventListener('message', listenMessageIframe);

    initialize();

    assistantListeners();

    programButtonListener();

    if (
        params(UPLOAD_ID_PARAM) ||
        params(NEW_LEAD_UPLOAD) ||
        params(EVENT_ID_PARAM) ||
        params(ESTIMATE_ID_PARAM) ||
        params(IGNORE_IFRAME) ||
        params(BOOK_APPOINTMENT_PARAM)
    ) {
        let widgetToggleBtnDom = document.querySelector(
            '.assistant__container .assistant__button'
        );
        if (widgetToggleBtnDom) widgetToggleBtnDom.click();
    }

    createStyles();

    const audio = document.getElementById('myAudio');
    if (audio) {
        audio.onended = () => {
            localStorage.setItem('loadAudio', true);
        };
    }
}

function listenMessageIframe(event) {
    if (event?.data?.type === HIDE_WIDGET) {
        const checkWidgetData = event?.data?.hide_widget ? 'add' : 'remove';
        if (!isModalPopup) {
            openOrCloseWidget(checkWidgetData);
        }
    }

    if (event?.data === OPEN_BOOK_APPOINTMENT_MODAL) {
        let assistant = document?.querySelector('.assistant__container');
        if (assistant) {
            assistant.classList.remove('modal');
        }
    }

    if (event?.data === 'close_modal_popup') {
        closeAssistant();
    }
}
