import { CLOSE, SCREEN } from '../../utils/constants';
import {
    appUrl,
    selectedCountry,
    setSelectedCodeNumber,
    setSelectedCountry,
} from '../../utils/variables';
import {
    formatPhoneNumber,
    toggleCountriesAskQs,
    toggleCountriesPhone,
    toggleCountriesConsult,
    validatePhoneNumber,
} from '../actions/countryCodeActions';

export function countryCodeListeners() {
    /* on click code number in dropdown */
    const codes = document.querySelectorAll('.phone__countryCode--option');
    codes.forEach((i) => {
        i.onclick = () => {
            setSelectedCountry(i.getAttribute('data-country'));
            setSelectedCodeNumber(i.getAttribute('data-country-code'));
            const screen = i.getAttribute('data-screen');

            /* Change selected flag icon */
            document.querySelectorAll('.selected-flag').forEach((j) => {
                j.setAttribute(
                    'src',
                    `${appUrl}/assets/images/svg/${i
                        .getAttribute('data-country')
                        .toLowerCase()}.svg`
                );
            });

            /* Change selected country code */
            document.querySelectorAll('.selected-country-code').forEach((j) => {
                j.textContent = `+${i.getAttribute('data-country-code')}`;
            });

            if (screen === SCREEN.REQUEST_CONSULT) {
                const phone = document.querySelector('#phoneConsult');
                const phoneWrapper = document.querySelector(
                    '.requestConsult__container div[data-form="phoneConsult"]'
                );

                phone.value = formatPhoneNumber(phone.value, selectedCountry);

                validatePhoneNumber(phoneWrapper, phone.value);
            } else if (screen === SCREEN.ASK_QUESTION) {
                const phone = document.querySelector('#phoneAskQuestion');
                const phoneWrapper = document.querySelector(
                    '.askQuestion__container div[data-form="phoneAskQuestion"]'
                );

                phone.value = formatPhoneNumber(phone.value, selectedCountry);

                validatePhoneNumber(phoneWrapper, phone.value);
            }

            toggleCountriesAskQs(CLOSE);
            toggleCountriesPhone(CLOSE);
            toggleCountriesConsult(CLOSE);
        };
    });

    /* Click outside to close code number country dropdown ASK QUESTION screen*/
    document.addEventListener('click', handleClickOutsidePageAskQuestion);

    /* Click outside to close code number country dropdown REQUEST CONSULTATION screen*/
    document.addEventListener('click', handleClickOutsidePageRequestConsultation);

    /* Click outside to close code number country dropdown PHONE screen*/
    document.addEventListener('click', handleClickOutsidePagePhone);
}

async function handleClickOutsidePageAskQuestion(evt) {
    const element = document.querySelector(
        `.phone__countryCode.${SCREEN.ASK_QUESTION}`
    );
    let targetElement = evt.target;

    do {
        if (targetElement == element) {
            return;
        }
        targetElement = targetElement.parentNode;
    } while (targetElement);

    toggleCountriesConsult(CLOSE);
}

async function handleClickOutsidePageRequestConsultation(evt) {
    const element = document.querySelector(
        `.phone__countryCode.${SCREEN.REQUEST_CONSULT}`
    );
    let targetElement = evt.target;

    do {
        if (targetElement == element) {
            return;
        }
        targetElement = targetElement.parentNode;
    } while (targetElement);

    toggleCountriesConsult(CLOSE);
}

export async function handleClickOutsidePagePhone(evt) {
    const element = document.querySelector(
        `.phone__countryCode.${SCREEN.PHONE}`
    );
    let targetElement = evt.target;

    do {
        if (targetElement == element) {
            return;
        }
        targetElement = targetElement.parentNode;
    } while (targetElement);

    toggleCountriesPhone(CLOSE);
}
