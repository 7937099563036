export const initModalWidgetStyles = () => `
.assistant__container *, :after, :before {
    box-sizing: border-box !important;
}
.assistant__container {
    z-index: 9999999;
    position: fixed; 
    width: 500px;
    height: 500px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


.assistant__container.show {
    height: 90%;
    max-height: 600px;
}

.assistant__container.modal.show {
    height: 90%;
    max-height: 335px;
}

.assistant__container.modal.show .subCLose__btn {
    background: rgb(0 0 0 / 76%);
}

.assistant__container .widgetContent__container {
    width: 100%;
    max-width: 100%;
}

.assistant__container .subCLose__btn {
    display: flex;
}

.assistant__container .subFooter--2 {
    box-shadow: none;
}
.assistant__container .subFooter--4 {
    width: 100%;
    border-radius: 0;
}

.widgetContent__container {
    box-shadow: 10px 10px 40px 20px #00000029;
}

.assistant__container.show + .overlay__popup {
    display: flex;
}

.assistant__container.show + .overlay__popup.hide {
    display: none;
}
`;
