export const footerStyles = `
.assistant__container .widget-footer {
    min-height: 86px;
    position: relative;
    width: 100%;
    transition: right .5s;
    margin-top: auto;
}
.assistant__container .subFooter--1 {
    width: 100%;
    position: absolute;
    height: 17px;
    top: -17px;
    left: 0;
    background: white;
    border-radius: 0 0 20px 0;
    z-index: 1;
}
.assistant__container .subFooter--2 {
    background: white;
    border-radius: 0 0 50px 50px;
    bottom: 82px;
    height: 19px;
    position: absolute;
    right: 0;
    width: 26px;
    box-shadow: -7px 13px 20px 0px rgb(0 0 0 / 16%);
}
.assistant__container .subFooter--3 {
    width: 19px;
    height: 30px;
    position: absolute;
    background: white;
    bottom: 0px;
    right: 81px;
    border-radius: 0 50px 50px 0;
}
.assistant__container .subFooter--4 {
    align-content: flex-end;
    align-items: center;
    background-color: white;
    border-radius: 0 0 12px 15px;
    color: gray;
    display: flex;
    font-size: 14px;
    height: 100%;
    justify-content: center;
    padding: 1rem;
    width: calc(100% - 85px);
    box-shadow: 0px 32px 40px 6px rgb(0 0 0 / 16%);
}
.assistant__container .subFooter--4 span {
    cursor: pointer;
}
.assistant__container .subFooter--5 {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    box-shadow: -19px -19px 0 -3px white;
    position: absolute;
    right: -15px;
    top: 0;
}
`;
