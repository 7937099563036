import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { CLOSE, OPEN, SCREEN } from '../../utils/constants';
import {
    countriesAskQs,
    countriesPhone,
    countriesConsult,
    selectedCountry,
    setCountriesAskQs,
    setCountriesConsult,
    setCountriesPhone,
} from '../../utils/variables';

export function toggleCountriesAskQs(state) {
    let open = CLOSE;

    open = countriesAskQs;

    if (state !== CLOSE && state !== OPEN) {
        if (open === CLOSE) {
            open = OPEN;
        } else open = CLOSE;
    } else open = state;

    setCountriesAskQs(open);

    const dropdown = document.querySelector(
        `.phone__countryCode--dropdown.${SCREEN.ASK_QUESTION}`
    );
    const code = document.querySelector(
        `.phone__countryCode.${SCREEN.ASK_QUESTION}`
    );

    if (open === OPEN) {
        dropdown.classList.remove('hidden');
        code.classList.add('open');
    } else {
        dropdown.classList.add('hidden');
        code.classList.remove('open');
    }
}

export function toggleCountriesConsult(state) {
    let open = CLOSE;

    open = countriesConsult;

    if (state !== CLOSE && state !== OPEN) {
        if (open === CLOSE) {
            open = OPEN;
        } else open = CLOSE;
    } else open = state;

    setCountriesConsult(open);

    const dropdown = document.querySelector(
        `.phone__countryCode--dropdown.${SCREEN.REQUEST_CONSULT}`
    );
    const code = document.querySelector(
        `.phone__countryCode.${SCREEN.REQUEST_CONSULT}`
    );

    if (open === OPEN) {
        dropdown.classList.remove('hidden');
        code.classList.add('open');
    } else {
        dropdown.classList.add('hidden');
        code.classList.remove('open');
    }
}

export function toggleCountriesPhone(state) {
    let open = CLOSE;

    open = countriesPhone;

    if (state !== CLOSE && state !== OPEN) {
        if (open === CLOSE) {
            open = OPEN;
        } else open = CLOSE;
    } else open = state;

    setCountriesPhone(open);

    const dropdown = document.querySelector(
        `.phone__countryCode--dropdown.${SCREEN.PHONE}`
    );
    const code = document.querySelector(
        `.phone__countryCode.${SCREEN.PHONE}`
    );
    if (!dropdown) return;

    if (open === OPEN) {
        dropdown.classList.remove('hidden');
        code.classList.add('open');
    } else {
        dropdown.classList.add('hidden');
        code.classList.remove('open');
    }
}

export function formatPhoneNumber(value, country) {
    if (!value) return '';

    value = value.toString();
    if (value.includes('(') && !value.includes(')')) {
        return value.replace('(', '');
    }
    var newValue = new AsYouType(country).input(value);
    return newValue;
}

export function validatePhoneNumber(phone, number) {
    if (isValidPhoneNumber(number, selectedCountry)) {
        phone.classList.add('valid');
        phone.classList.remove('invalid');
    } else {
        phone.classList.remove('valid');
        phone.classList.add('invalid');
    }
}
