export const bookAppointmentLogin = `
* {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}
.wrapper-login {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    position: fixed;
    inset: 0;
    overflow-y: auto;
    overflow: hidden;
}

.wrapper-login .errorText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgb(220, 53, 69);
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 12px;
}

.wrapper-login .container{
    width: 100%;
    height: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    background: rgb(245, 246, 250)
}

.wrapper-login .container-left {
    max-width: 680px;
    padding: 0px;

    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/png/background_login_portal.png);
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: center;
    position: relative;
    display: flex;
}

.wrapper-login .container-left .wrap-left {
    display: flex;
    flex-direction: column;
}

.wrapper-login .container-right {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 100%;
    justify-content: center;
    padding: 20px;
}

.container-left .logo-project {
    margin: 70px 0px 50px;
}

.container-left .logo-project img {
    width: 160px;
    max-width: 100%;
}

.container-left .info-project {
    padding: 24px;
    width: 520px;
    max-width: 520px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 16px;
    background: rgb(255, 255, 255);
}

.container-left .info-project .info-item {
    display: flex;
    align-items: center;
    gap: 10px;
}

.container-left .info-project .info-item .icon {
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: rgba(242, 244, 246, 0.96);
    margin-bottom: 0px;
}

.container-left .info-project .info-item .text {
    color: rgb(14, 64, 95);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.container-left a {
    transition: all 0.4s ease 0s;
    color: rgb(14, 64, 95);
    text-decoration: none;
}

.wrapper-login ion-icon {
    font-size: 24px;
}

.container-right .wrap-right {
    osition: relative;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    font-style: normal;
    width: 100%;
    padding: 12px;
    border: 1px solid rgb(240, 245, 245);
    box-shadow: rgba(16, 24, 24, 0.03) 0px 0px 4px 1px;
    border-radius: 14px;
    max-width: 480px;
    padding: 40px;
}

.wrap-right .logo-project {
    margin: 0px auto 50px;
}

.wrap-right .logo-project img {
    width: 160px;
    max-width: 100%;
}

.wrap-right .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 130%;
    padding: 20px;
    text-align: center;
    letter-spacing: -0.002em;
    color: rgb(6, 30, 45);
}

.wrap-right .description {
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: rgb(131, 146, 165)
}
.fullName-screen__wrapper {
    height: calc(100% - 137px);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4f4f4;
}

.wrapper-login .phone__wrappers,
.wrapper-login .validateDigits__wrappers {
    background: none;
    padding: 0;
}

.wrapper-login .phone__wrappers .card,
.wrapper-login .validateDigits__wrappers .card{
    padding: 0;
}

.wrapper-login .phone__wrappers .phone__actions,
.wrapper-login .validateDigits__actions{
    padding: 0;
    width: 100%;
}

.wrapper-login .phone__wrappers .phoneContinue__btn,
.wrapper-login .validateDigitsContinue__btn {
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    gap: 10px;
    background: var(--primary-color);
    border-radius: 4px;
    width: 100%;
}

.wrapper-login .phone__wrappers .phoneContinue__btn:hover,
.wrapper-login .validateDigitsContinue__btn:hover {
    border: 1px solid var(--primary-color);
    color: white;
    opacity: 0.7;
}

.wrapper-login .phone__wrappers .phoneContinue__btn .loading__wrapper,
.wrapper-login .validateDigitsContinue__btn .loading__wrapper {
    border-radius: 4px;
}

.hidden {
    display: none!important;
}
`;
