import './js/utils/constants';
import { getFormByFormId } from './js/services/formServices';
/* Modules */
import './js/modules/widget';
import {
    appUrl, appointmentChannelId, leadInfo, setAccessToken,
    setAppUrl,
    setAppointmentChannelId,
    setForm,
    setFormId, setIsModalPopup, setLeadInfo, setLeadTextId,
    setPhoneToCall,
    setPicture,
    setPrimaryColor,
    setTooltip,
    sourceParam,
} from './js/utils/variables';
import { loadWidget } from './js/modules/widget';
import { apiClient } from './js/utils/api';
import { params } from './js/modules/events/movePages';
import { APPOINTMENT_CHANNEL_ID, BOOK_APPOINTMENT_PARAM, DESKTOP_SCREEN, ESTIMATE, ESTIMATE_ID_PARAM, EVENT_ID_PARAM, FULL_PAGE, IGNORE_IFRAME, MOBILE_SCREEN, NEW_LEAD_UPLOAD, UPDATE_APPOINTMENT_CHANNEL, UPLOAD_ID_PARAM } from './js/utils/constants';
import { UAParser } from 'ua-parser-js';
import { createLead } from "./js/services/leadServices";
import { addIconLib, createStylesAppointmentLogin, generateLoginAppointmentBooking } from './js/modules/actions/generateLoginAppointmentBooking';
import { inputPhoneUI } from './js/modules/actions/appointmentLoginPhoneCode';
const selector = document.querySelector('script[data-id="assistant"]');

const url = selector.getAttribute('src').includes('widget.js')
    ? selector
        .getAttribute('src')
        .substring(0, selector.getAttribute('src').length - 10)
    : window.location.origin;

const formId = params('form_id') ? params('form_id') : selector.getAttribute('form');
const widgetModal = selector.getAttribute('data-popup');
const appointmentChannel = params('appointment_channel_id') ? params('appointment_channel_id') : selector.getAttribute('appointment_channel');

setAppUrl(url);
setFormId(formId);
getData();
setIsModalPopup(!!widgetModal);
setAppointmentChannelId(appointmentChannel);

async function getData() {
    const form = await getFormByFormId(formId, appointmentChannelId || appointmentChannel);
    setTooltip(form.data.title_tooltip);
    setPicture(form.data.picture);
    await initData(form.data);
}

function listenMessageIframe(event) {
    if (event.data === UPDATE_APPOINTMENT_CHANNEL) {
        const assistantIframeTemp = document.querySelector(
            '.assistant__container .assistant__content--iframe'
        );
        if (assistantIframeTemp) {
            assistantIframeTemp.contentWindow.postMessage(
                UPDATE_APPOINTMENT_CHANNEL,
                '*'
            );
        } else {
            parent.postMessage(UPDATE_APPOINTMENT_CHANNEL, "*");
            document.querySelector('iframe').contentWindow.postMessage(UPDATE_APPOINTMENT_CHANNEL, "*");
        }
    }

}

export async function initData(response) {
    setPrimaryColor(response.color_theme);
    setPhoneToCall(response.project?.business_phone);
    setAccessToken(response.token);

    await createLeadFromImageUploadLink();

    window.addEventListener('message', listenMessageIframe);

    const parser = new UAParser(window.navigator.userAgent);

    if (parser.getDevice().type === "mobile" && apiClient.inIframe()) {
        document.querySelector('body').insertAdjacentHTML('beforeend', "<div style='top:0;left:0;width:100%;height:100%;position:absolute;' id='redirectOverlay'></div>");
        document.getElementById('redirectOverlay').addEventListener('click', () => {
            top.location.href = window.location.origin + `?form_id=${params('form_id')}&is_open_assistant=true`;
        }, { once: true });
    }

    if ((apiClient.inIframe() && !params(IGNORE_IFRAME)) || ((params(FULL_PAGE) || params(APPOINTMENT_CHANNEL_ID)) && !params(IGNORE_IFRAME))) {
        let landingPageParam = '';
        let fullWidth = !!params(FULL_PAGE) || !!params(APPOINTMENT_CHANNEL_ID);
        let htmlContent = '';
        const staffIdParam = params('staff_id') ? `&staff_id=${params('staff_id')}` : '';
        const procedureIdParam = params('procedure_id') ? `&procedure_id=${params('procedure_id')}` : '';
        if (params(UPLOAD_ID_PARAM)) {
            landingPageParam = `&${UPLOAD_ID_PARAM}=${params(UPLOAD_ID_PARAM)}`;
        } else if (params(EVENT_ID_PARAM)) {
            landingPageParam = `&${EVENT_ID_PARAM}=${params(EVENT_ID_PARAM)}`;
        } else if (params(ESTIMATE_ID_PARAM)) {
            landingPageParam = `&${ESTIMATE_ID_PARAM}=${params(ESTIMATE_ID_PARAM)}`;
        }

        if (params(ESTIMATE)) {
            landingPageParam += `&${ESTIMATE}=true`
        }

        if (staffIdParam) {
            landingPageParam += staffIdParam
        }

        if (procedureIdParam) {
            landingPageParam += procedureIdParam
        }

        if (params(APPOINTMENT_CHANNEL_ID) && response?.appointment_channel?.appointment_channel_name) {
            document.title = response.appointment_channel.appointment_channel_name;
        }

        if ((params(FULL_PAGE) && params(BOOK_APPOINTMENT_PARAM) && DESKTOP_SCREEN) || ((params(FULL_PAGE) && params('staff_id') && params('procedure_id')) && DESKTOP_SCREEN)) {
            createStylesAppointmentLogin()
            addIconLib()
            setForm(response)
            htmlContent = generateLoginAppointmentBooking(response.project)
            if (document?.querySelector(".wrapper-login")) {
                document?.querySelector(".wrapper-login").remove()
            }
            document.body.insertAdjacentHTML('beforeend', `${htmlContent}`);
            inputPhoneUI()
            if (leadInfo?.id) {
                renderIframeWidget(landingPageParam, fullWidth, true)
            }
        } else {
            renderIframeWidget(landingPageParam, fullWidth, false)
        }

    } else {
        loadWidget();
    }
}

async function createLeadFromImageUploadLink() {
    if (params(NEW_LEAD_UPLOAD)) {
        let newLead = await createLead({
            'first_name': params('first_name'),
            'last_name': params('last_name'),
            'email': params('email'),
            'phone': params('phone')
        });

        setLeadInfo(newLead.data);
        setLeadTextId(newLead.data.text_id);
    }
}

function renderIframeWidget(landingPageParam, fullWidth, isAppointmentWebpage) {
    const isBookAppointmentFlow = (params('staff_id') && params('procedure_id')) || params(APPOINTMENT_CHANNEL_ID)
    const isBookingFlow = (isBookAppointmentFlow && params(FULL_PAGE) || params(APPOINTMENT_CHANNEL_ID)) && !MOBILE_SCREEN
    let htmlContent = `
    <div style="background-color: #f1f4f6; width: ${
        isAppointmentWebpage ? '100%' : '100vw'
    }; height: ${
        isAppointmentWebpage ? '100%' : '100vh'
    }; display: flex; flex-direction: column; align-items: center; gap: 30px; ${
        isBookingFlow ? '' : 'align-items: center'
    };" class="block__iframe">
        <iframe src="${appUrl}?form_id=${formId}&ignore_iframe=1${landingPageParam}${
        fullWidth ? '&full_page=1' : ''
    }${
        params(APPOINTMENT_CHANNEL_ID)
            ? `&appointment_channel_id=${params(APPOINTMENT_CHANNEL_ID)}`
            : ''
    }" style="border: none; border-radius: 16px; box-shadow: rgb(204 204 204) 0px 0px 8px; ${
        isBookingFlow
            ? 'margin-top: 66px; @media only screen and (max-width: 600px){width: 100%;height: 100%}'
            : ''
    } "${
        isBookingFlow ? `width="800" height="612"` : `width="540" height="612"`
    }></iframe>
        <span class="footer" style="font-size: 14px; color: gray; font-family: Poppins, sans-serif; cursor: pointer;">
                <span>Powered by <b style="margin-left: 3px;"><u class="powered_by">
                ${
                    params('appUrl')?.includes('kloudmd')
                        ? 'KloudMD'
                        : 'PilotPractice'
                }
                </u></b></span>
            </span>
    </div >
`;
    if (fullWidth) {
        const styleTag = document.createElement('style');
        styleTag.innerHTML = `
        .block__iframe iframe {
            @media only screen and (max-width: 600px){width: 100%;height: 100%}
        }
    `;
        document.head.append(styleTag);
    }

    if (isAppointmentWebpage) {
        document.querySelector(".wrapper-login .container-right").innerHTML = ""
        document.querySelector(".wrapper-login .container-right").insertAdjacentHTML('beforeend', `${htmlContent}`);
    } else {
        document.querySelector('body').style.margin = 0;
        document.body.insertAdjacentHTML('beforeend', `${htmlContent}`);
    }

    let url = params('appUrl');
    let companyUrl = url?.includes('kloudmd')
        ? 'https://kloudmd.com'
        : 'https://pilotpractice.com';

    document.querySelectorAll('.footer span').forEach((i) => {
        i.onclick = () => {
            window.parent.parent.location.href = `${companyUrl}${
                sourceParam ? `?source=${sourceParam}` : ''
            }`;
        };
    });
}
