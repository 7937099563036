import { bookAppointmentLogin } from "../../styles/bookAppointmentLogin";
import { primaryColor, setProjectId } from "../../utils/variables";

export function generateLoginAppointmentBooking(projectInfo) {
    document.body.style.setProperty('--primary-color', primaryColor);
    setProjectId(projectInfo?.project_id)
    return `
        <div class="wrapper-login">
        <!-- Load error -->
        <div class="toast__container error animate__animated">
            <span class="close-error">
                <i class="ri-close-line"></i>
            </span>
            <span class="error-msg"></span>
        </div>
            <div class="container">
                <div class="container-left">
                    <div class="wrap-left">
                        <div class="logo-project">
                            <img src="${projectInfo?.business_photo || ""}" alt="logo"/>
                        </div>
                        <div class="info-project">
                        ${(projectInfo?.location?.length > 0 && projectInfo?.location[0]?.full_address) ? `
                                <div class="info-item">
                                    <span class="icon">
                                        <ion-icon name="location-outline"></ion-icon>
                                    </span>
                                    <span class="text">
                                        ${projectInfo?.location[0]?.full_address || ""}
                                    </span>
                                </div>
                            ` : ""
        }
                        ${projectInfo?.business_phone ? `
                            <div class="info-item">
                                <span class="icon">
                                <ion-icon name="call-outline"></ion-icon>
                                </span>
                                <span class="text">
                                <a
                                    href="tel:${projectInfo?.business_phone || ""}"
                                >
                                    ${projectInfo?.business_phone || ""}
                                </a>
                                </span>
                            </div>
                            ` : ""
        }
                        ${projectInfo?.business_email ? `
                            <div class="info-item">
                                <span class="icon">
                                <ion-icon name="mail-outline"></ion-icon>
                                </span>
                                <span class="text">
                                    <a
                                        href="mailto:${projectInfo?.business_email || ""}"
                                    >
                                        ${projectInfo?.business_email || ""}
                                    </a>
                                </span>
                            </div>
                            ` : ""
        }
                        ${projectInfo?.website ? `
                            <div class="info-item">
                                <span class="icon">
                                <ion-icon name="globe-outline"></ion-icon>
                                </span>
                                <span class="text">
                                <a
                                    href="${projectInfo?.website || ""}"
                                    target="_blank"
                                >
                                ${projectInfo?.website || ""}
                                </a>
                                </span>
                            </div>
                            ` : ""
        }
                        </div>
                    </div>
                </div>
                <div class="container-right">
                    <div class="wrap-right">
                    </div>
                </div>
            </div>
        </div>
  `;
}

export function createStylesAppointmentLogin() {
    const styleTag = document.createElement('style');
    styleTag.innerHTML = bookAppointmentLogin.replace(/^\s+|\n/gm, '');

    document.head.appendChild(styleTag);
}

export function addIconLib() {
    const ionicLib = document.createElement('script');
    ionicLib.type = 'module';
    ionicLib.src =
        'https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js';
    document.head.append(ionicLib);
}