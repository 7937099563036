export const tooltip = `
.assistant__container .tooltip__text {
    width: max-content;
    right: 100px;
    bottom: 5px;
    position: absolute;
    border-radius: 15px;
    padding: 1rem;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
    z-index: 1;
    font-family: Nunito,system-ui,BlinkMacSystemFont,-apple-system,sans-serif;
    font-size: 16px;
    background-color: white;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #4a4a4a;
}
.assistant__container .tooltip__text .hidden {
    dispplay:hidden;
}
.assistant__container .tooltip__text .close-tooltip-btn {
    position: absolute;
    left: -8px;
    top: -6px;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    padding: 5px 5px;
    background-color: gray;
    cursor:pointer;
}

.assistant__container .tooltip__icon {
    line-height: 1;
}

.assistant__container .tooltip__content {
    font-size: 16px;
    font-weight: 500;
    max-width: 390px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.assistant__container .tooltip__text .close-tooltip-btn .tooltip-btn-icon {
  position: absolute;
  color:white;
  top:3px;
  right:3px;
  height:15px;
  width:15px;
}
.assistant__container .tooltip__text span {
    margin-right: 8px;
    font-size: 23px;
    position: relative;
}
.assistant__container .tooltip__text::after {
    content: "";
    position: absolute;
    top: 33%;
    left: 101%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent transparent white;
}
.assistant__container.show .tooltip__text {
    display: none;
}
`;
