import { SCREEN } from '../../utils/constants';

import { getClassScreen, navigate } from './movePages';
import {
    setPhone,
    setEstimateInfo,
    selectedAddons,
    isInInstantQuote,
    isBookAppointment,
    isOTPTimeOut,
    primaryColor,
    setLeadTextId,
    setLeadInfo,
    hidePricing,
} from '../../utils/variables';
import { loadSelectedProcedures } from '../../views/components/selectedProcedures';
import {
    formatPhoneNumber,
    toggleCountriesPhone,
    validatePhoneNumber,
} from '../actions/countryCodeActions';

import parsePhoneNumberFromString, {
    isValidPhoneNumber,
} from 'libphonenumber-js';

import {
    selectedCountry,
    fullName,
    phone,
    email,
    emailCheck,
    selectedInterest,
    selectedProcedures,
    visitorId,
    providerId,
    appUrl,
} from '../../utils/variables';

import { createLead, updateLeadStep } from '../../services/leadServices';
import { toggleLoading } from '../actions/loading';
import { handleInstantQuoteSummary, instantQuoteSummaryListeners, navigateConsultSummary } from './instantQuoteSummaryListeners';
import { toastError } from '../../views/components/error';
import { sendOTPCodeWithTimer } from '../actions/validateDigitsActions';
import { handelConsultSummaryBtn } from './consultListeners';

export function phoneListeners() {
    formatAndValidatePhone();
    toggleCountryCode();

    document.getElementById('phone').addEventListener('focus', () => {
        let oldPhoneElement = document.querySelector(".phone--element.old-phone");
        if (oldPhoneElement) {
            oldPhoneElement.style.backgroundColor = "#fff";
        }
    });

    document.getElementById('phone').addEventListener('blur', () => {
        let oldPhoneElement = document.querySelector(".phone--element.old-phone");
        if (oldPhoneElement) {
            oldPhoneElement.style.backgroundColor = "#ddd";
        }
    });

    const phoneElement = document.querySelector(
        '.phone__container .phone--element'
    );

    document.getElementById('phone').onfocus = () => {
        phoneElement.style.borderColor = `${primaryColor}`;
    };

    document.getElementById('phone').onblur = () => {
        phoneElement.style.borderColor = `lightgrey`;
    };

    if (phoneElement) {
        phoneElement.addEventListener('click', (e) => {
            e.preventDefault();
            let error = false;
            let input = document.querySelector('.phone--input');

            if (
                !input.value ||
                (input.getAttribute('id') === 'phone' &&
                    !isValidPhoneNumber(input.value, selectedCountry))
            ) {
                input.classList.add('invalid');
                error = true;
            }
            if (error) {
                return;
            }
        });
    }

    /* On click enter */
    const screenPhone = document.querySelector('.screen--phone');

    if (screenPhone) {
        screenPhone.addEventListener('keydown', (event) => {
            if (
                event.key === 'Enter' &&
                !document
                    .querySelector('.phoneContinue__btn')
                    ?.classList.contains('disabled')
            ) {
                document.querySelector('.phoneContinue__btn')?.click();
            }
        });
    }

    /* on click Continue after input phone number*/
    document.querySelector('.phoneContinue__btn').addEventListener('click', handleClickPhoneContinue);

    /* on click back button */
    document.querySelector('.phoneBack__btn').addEventListener('click', handlePhoneBackBtn);
}

async function handleClickPhoneContinue() {
    if (isInInstantQuote) {
        const validPhone = parsePhoneNumberFromString(
            phone,
            selectedCountry
        );

        let selectedAddonIds = selectedAddons?.map((addon) => addon.id);

        const data = {
            full_name: fullName,
            formNumberCode: validPhone.country,
            phone: validPhone.number,
            email: email,
            email_optin: emailCheck,
            interest: selectedInterest,
            procedures_interested_in: selectedProcedures,
            ...(selectedAddonIds && {
                addons_interested_in: selectedAddonIds,
            }),
            ...(visitorId && { visitor_id: visitorId }),
            ...(providerId && { provider_id: providerId }),
        };
        const loaderIcon = document.querySelector(
            '.phoneContinue__btn .loader__icon'
        );
        toggleLoading(true);
        try {
            document
                .querySelector('.phoneContinue__btn')
                ?.classList.add('disabled');
            document
                .querySelector('.phoneContinue__btn .loading__wrapper')
                ?.classList.remove('hidden');
            if (loaderIcon) {
                loaderIcon.innerHTML = '';
                loaderIcon.insertAdjacentHTML(
                    'beforeend',
                    `<img src="${appUrl}/assets/icons/rolling.png" />`
                );
            }
            const lead = await createLead(data);
            setLeadTextId(lead.data.text_id);
            setLeadInfo(lead.data);
            const estimates = await updateLeadStep(lead.data.id, 'estimated', '');
            if (estimates) {
                setEstimateInfo(estimates?.data);
            }
            loadSelectedProcedures();
            if (hidePricing) {
                handelConsultSummaryBtn();
            } else {
                handleInstantQuoteSummary();
                toggleLoading(false);
                instantQuoteSummaryListeners();
                navigate(SCREEN.INSTANT_QUOTE_SUMMARY).then(() => {
                    document
                        .querySelector('.phoneContinue__btn')
                        ?.classList.remove('disabled');
                });
            }
        } catch (err) {
            toastError(err.response?.data?.message);
            toggleLoading(false);
        } finally {
            if (loaderIcon) {
                loaderIcon.innerHTML = '';
                loaderIcon.insertAdjacentHTML(
                    'beforeend',
                    `<i class="ri-arrow-right-line"></i>`
                );
            }
            document.querySelector('.phoneContinue__btn .loading__wrapper')?.classList.add('hidden');

            document.querySelector('.phoneContinue__btn')?.classList.remove('disabled');
        }
    } else if (isBookAppointment) {
        const loaderIcon = document.querySelector(
            '.phoneContinue__btn .loader__icon'
        );
        try {
            document
                .querySelector('.phoneContinue__btn')
                ?.classList.add('disabled');
            document
                .querySelector('.phoneContinue__btn .loading__wrapper')
                ?.classList.remove('hidden');
            if (loaderIcon) {
                loaderIcon.innerHTML = '';
                loaderIcon.insertAdjacentHTML(
                    'beforeend',
                    `<img src="${appUrl}/assets/icons/rolling.png" />`
                );
            }
            if (isOTPTimeOut) {
                await sendOTPCodeWithTimer();
            }
            document.getElementById('validateDigits0').focus();
            navigate(SCREEN.VALIDATE_DIGITS);
            const phoneNumObj = parsePhoneNumberFromString(
                phone,
                selectedCountry
            );
            if (
                document.querySelector(
                    '.validateDigits--sub-title strong'
                )
            ) {
                document.querySelector(
                    '.validateDigits--sub-title strong'
                ).innerHTML = phoneNumObj.number;
            }
        } catch (error) {
            toastError(error.response?.data?.message);
        } finally {
            if (loaderIcon) {
                loaderIcon.innerHTML = '';
                loaderIcon.insertAdjacentHTML(
                    'beforeend',
                    `<i class="ri-arrow-right-line"></i>`
                );
            }
            document
                .querySelector('.phoneContinue__btn .loading__wrapper')
                ?.classList.add('hidden');

            document.querySelector('.phoneContinue__btn')?.classList.remove('disabled');
        }
    }
}

async function handlePhoneBackBtn() {
    const emailInput = document.getElementById('email');
    if (emailInput !== document.activeElement && isInInstantQuote) {
        emailInput.focus();
    }
}

export function toggleCountryCode() {
    document.querySelector(`.phone__selected-code.${SCREEN.PHONE}`).onclick =
        () => toggleCountriesPhone();
}

export function formatAndValidatePhone() {
    const phone = document.querySelector('#phone');
    const invalidIcon = document.querySelector('.phone__invalid');
    const validIcon = document.querySelector('.phone__valid');

    phone.addEventListener('keydown', (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
        }
    });

    phone.oninput = (e) => {
        const newValue = formatPhoneNumber(e.target.value, selectedCountry);
        const phoneWrapper = document.querySelector('.phone__wrapper--input');
        const phoneElement = document.querySelector('.phone--element');
        phone.value = newValue;

        validatePhoneNumber(phoneWrapper, e.target.value);

        setPhone(e.target.value);
        if (isValidPhoneNumber(phone.value, selectedCountry)) {
            phoneWrapper.classList.remove('invalid');
            invalidIcon.classList.add('hidden');
            validIcon.classList.remove('hidden');
            document
                .querySelector('.phoneContinue__btn')
                .classList.remove('disabled');
            phoneElement.classList.remove('invalid');
        } else {
            phoneWrapper.classList.add('invalid');
            invalidIcon.classList.remove('hidden');
            document
                .querySelector('.phoneContinue__btn')
                .classList.add('disabled');
            phoneElement.classList.add('invalid');
        }
    };
}
