export const loadingStyles = `
.assistant__container .loading__wrapper{
    background-color: transparent;
    position: absolute;
    width: 100%;
    top: 0;
    box-shadow: none;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    z-index: 10;
    opacity: 1;
    right: 0;
    transition: right .5s;
    height: 100%;
    flex-direction: column;
}
.assistant__container .loading__wrapper .loader {
    height: 100%;
    display: flex;
    width: 100%;
    background: white;
    border-radius: 15px 15px 10px 0;
}
.assistant__container .loading__wrapper svg {
    width: 75px;
}`;
