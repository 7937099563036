import { generateWidget } from '../modules/actions/generateWidget';
export function initialize() {
    const ionicLib = document.createElement('script');
    ionicLib.type = 'module';
    ionicLib.src =
        'https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js';

    const ionic = document.createElement('script');
    ionic.noModule = true;
    ionic.src = 'https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js';

    document.head.append(ionicLib);
    document.head.append(ionic);
    document.body.insertAdjacentHTML('beforeend',`${generateWidget()}`);
}
