export const initWidgetStyles = (primaryColor) => `
.assistant__container *, :after, :before {
    box-sizing: border-box !important;
}
.assistant__container {
    z-index: 99999999999;
    position: fixed; 
    bottom: 20px; 
    right: 20px;
    font-weight: 300;
    font-family: Nunito,system-ui,BlinkMacSystemFont,-apple-system,sans-serif !important;
}
.assistant__container.show {
    height: 90%;
    max-height: 800px;
}
.assistant__container .hidden {
    display: none !important;
}
.assistant__container .assistant__button {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 11;
    background-color: ${primaryColor};
    border: 5px solid white;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.assistant__container .assistant__button .open-btn > img {
    position: absolute;
    border-radius: 50%;
    object-fit: cover;
    top: 0;
}
.assistant__container .assistant__button:hover {
    bottom: 3px;
}
.assistant__container .open-btn {
    display: inline;
    position: relative;
    width: 100%;
    height: 100%;
}
.assistant__container.show .open-btn {
    display: none;
}
.assistant__container .close-btn {
    display: none;
}
.assistant__container.show .close-btn {
    display: inline;
}
.assistant__container .btn-icon {
    height: 40px;
    width: 40px;
    color: white;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.assistant__container .widgetContent__container {
    width: 380px;
    max-width: 380px;
    right: 0;
    bottom: 0;
    height: 100%;
    border-radius: 15px;
    background-color: transparent;
    z-index: 10;
    display: none;
}
.assistant__container.show .widgetContent__container {
    display: block;
}
.assistant__container .widgetContent__container.hidden {
    max-height: 0px;
}
.assistant__container .widget__body {
    background-color: transparent;
    border-radius: 15px;
    box-shadow: rgb(0 0 0 / 16%) -20px -20px 40px 0px;
    height: 100%;
}
.assistant__container .assistant__content--iframe {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 15px;
}
.assistant__container .subCLose__btn {
    position: absolute;
    background: rgba(0,0,0,0.07);
    height: 38px;
    width: 38px;
    font-size: 21px;
    align-items: center;
    justify-content: space-around;
    border-radius: 8px;
    top: 14px;
    right: 12px;
    cursor: pointer;
    display: none;
    color: white;
    z-index: 10;
}
.assistant__container .subCLose__btn:hover {
    background: rgba(0,0,0,0.15);
    transform: scale(1.05);
}

.animated {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0, 0, 0);
        transform: translateY(0, 0, 0);
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(0, 0, 0);
        transform: translateY(0, 0, 0);
    }
}

@-webkit-keyframes fadeOutDown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@keyframes fadeOutDown {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
    }
}

@-webkit-keyframes verticalShake {
    from {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }

    to {
        opacity: 1;
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

@keyframes verticalShake {
    from {
        -webkit-transform: translateY(5px);
        transform: translateY(5px);
    }

    to {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
}

.fadeInUp {
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
}

.fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
}

.verticalShake {
    animation: verticalShake 1.5s infinite;
    animation-direction: alternate-reverse;
    animation-timing-function: linear;
}
.overlay__popup {
    position: fixed;
    inset: 0;
    opacity: 0.699999988079071;
    background: #000;
    display: none;
}

.desktop-ver.assistant__container {
    width: 100%  !important;
    bottom: 0 !important; 
    right: 0 !important;
}
.desktop-ver.assistant__container.show {
    height: 100%;
    min-height: 100%;
    max-height: 100% !important;
    max-width: 100%  !important;
}
.desktop-ver.assistant__container .widgetContent__container {
    width: 100%;
    max-width: 100%;
}
.desktop-ver.assistant__container .tooltip__text {
    max-width: 65vw !important;
}
.desktop-ver .assistant__button {
    z-index: -1 !important;
    bottom: 20px !important;
    right: 20px !important;
}
.desktop-ver .assistant__button:hover {
    bottom: 23px;
}
.desktop-ver.assistant__container .tooltip__text {
    right: 110px !important;
    bottom: 25px !important;
}
.desktop-ver.assistant__container .assistant__content--iframe {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
}
.desktop-ver.assistant__container .subCLose__btn {
    display: flex;
}
.desktop-ver.assistant__container .subFooter--2 {
    box-shadow: none;
}
.desktop-ver.assistant__container .subFooter--4 {
    width: 100%;
    border-radius: 0;
}
`;