import parsePhoneNumberFromString from "libphonenumber-js";
import { sendOTPCode } from "../../services/digitsCodeService";
import { phone, selectedCountry, setIsOTPTimeOut } from "../../utils/variables";

export async function OTPTimer() {
    document.querySelector('.validateDigits--resend-otp')?.classList.add('validateDigits--resend-otp--disabled');
    let timeleft = 30;
    let blockTimer = document.querySelector('.validateDigits__resend-timer')
    if (!blockTimer) return;
    blockTimer.textContent = `00:${timeleft}`;
    let downloadTimer = setInterval(function () {
        if (timeleft >= 0) {
            blockTimer.textContent = `00:${('0' + timeleft).slice(-2)}`;
            if (timeleft === 0) {
                if (document.querySelector('.validateDigits__resend-timer')) {
                    document.querySelector('.validateDigits__resend-timer').style.display = 'none';
                }
                document.querySelector('.validateDigits--resend-otp')?.classList.remove('validateDigits--resend-otp--disabled');
            }
        } else if (timeleft < 0) {
            clearInterval(downloadTimer);
            setIsOTPTimeOut(true);
        }
        timeleft -= 1;
    }, 1000);
    setIsOTPTimeOut(false);
    document.querySelector('.validateDigits__resend-timer').style.display = 'block';
}

export async function sendOTPCodeWithTimer() {
    OTPTimer();
    await sendOTPCode(parsePhoneNumberFromString(phone, selectedCountry).number);
}