import axios from 'axios';
import { params } from '../modules/events/movePages';
import { getLocalStorage } from './localStorage';
import { access_token } from './variables';

/* Api services */
const apiUrl = (url) => {
    return `${process.env.BASE_URL}/${url}`;
};

const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};
const token = () => {
    if (access_token) {
        return access_token;
    } else if (inIframe()) {
        return params('token');
    }
};

function getCsrfToken() {
    let cookieStr = document.cookie; // could be "XSRF-TOKEN=...; AnotherCookie=...; ..."
    let cookiesArray = cookieStr.split('; ');

    let xsrfToken;
    cookiesArray.forEach(cookie => {
        let [name, value] = cookie.split('=');
        if (name === 'XSRF-TOKEN') {
            xsrfToken = value;
        }
    });

    return xsrfToken;
}

const config = () => ({
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token()
    }
});

const GET = (url) => {
    return axios.get(apiUrl(url), config());
};

const POST = (url, data) => {
    return axios.post(apiUrl(url), data, config());
};

const PUT = (url, data) => {
    return axios.put(apiUrl(url), data, config());
};

export const apiClient = {
    GET,
    POST,
    PUT,
    config,
    apiUrl,
    inIframe
};
