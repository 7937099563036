import { params } from '../modules/events/movePages';
import { apiClient } from '../utils/api';

export async function createLead(data) {
    if (parseInt(params('has_source')) === 1) {
        data['source'] = params('source');
    }
    return await apiClient.POST(`api/leads`, data);
}

export async function updateLead(id, data) {
    return await apiClient.POST(`api/leads/${id}`, data);
}

export async function updateLeadStep(id, step, sessionEnded = 'false') {

    if (step === 'question') {
        return await apiClient.PUT(
            `api/leads/${id}/step/${step}/true`
        );
    }

    return await apiClient.PUT(
        `api/leads/${id}/step/${step}${sessionEnded ? `/${sessionEnded}` : ''}`
    );
}
